@font-face {
    font-family: 'Radikal';
    src: url('./fonts/radikal/Radikal-light.eot') format('embedded-opentype'),
    url('./fonts/radikal/Radikal-light.woff') format('woff'),
    url('./fonts/radikal/Radikal-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Radikal';
    src: url('./fonts/radikal/Radikal-regular.eot') format('embedded-opentype'),
    url('./fonts/radikal/Radikal-regular.woff') format('woff'),
    url('./fonts/radikal/Radikal-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Radikal';
    src: url('./fonts/radikal/Radikal-medium.eot') format('embedded-opentype'),
    url('./fonts/radikal/Radikal-medium.woff') format('woff'),
    url('./fonts/radikal/Radikal-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Radikal';
    src: url('./fonts/radikal/Radikal-bold.eot') format('embedded-opentype'),
    url('./fonts/radikal/Radikal-bold.woff') format('woff'),
    url('./fonts/radikal/Radikal-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src:
            local('Public Sans'),
            url('./fonts/public-sans/PublicSans-Light.woff2') format('woff2'),
            url('./fonts/public-sans/PublicSans-Light.woff') format('woff');
}

@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src:
            local('Public Sans'),
            url('./fonts/public-sans/PublicSans-Regular.woff2') format('woff2'),
            url('./fonts/public-sans/PublicSans-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src:
            local('Public Sans'),
            url('./fonts/public-sans/PublicSans-Medium.woff2') format('woff2'),
            url('./fonts/public-sans/PublicSans-Medium.woff') format('woff');
}
